import BasePair from "./BasePair";

interface StaticBasePairProps {
  left: string;
  right: string;
  canRotate?: boolean;
}

const StaticBasePair = ({ left, right, canRotate }: StaticBasePairProps) => {
  return (
    <BasePair
      narrow={true}
      rowIndex={0}
      left={left}
      right={right}
      focusMode={false}
      arrowMode={false}
      focused={undefined}
      dx={0}
      dy={0}
      canFocus={false}
      canSwapUp={false}
      canSwapDown={canRotate || false}
      canRotateCCW={canRotate || false}
      canRotateCW={canRotate || false}
      leftAccessibleName={left}
      leftAccessibleShortName={left}
      rightAccessibleName={right}
      rightAccessibleShortName={right}
      solved={false}
      didFocus={() => {}}
      didClick={() => {}}
      didSwapLeftRight={() => {}}
      didSwapUp={() => {}}
      didSwapDown={() => {}}
      didRotateCCW={() => {}}
      didRotateCW={() => {}}
      onPointerDown={() => {}}
    />
  );
};

export default StaticBasePair;
