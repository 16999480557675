import React, { useRef } from "react";
import "./Swap.css";

interface SwapProps {
  accessibleShortName: string;
  direction: string;
  visible: boolean;
  onSwap: (e: React.MouseEvent<HTMLElement>) => void;
}

const Swap = ({
  accessibleShortName,
  direction,
  visible,
  onSwap,
}: SwapProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  let getClassName = () => {
    let className = "Swap swap-" + direction;
    if (visible) {
      return className + " swap-focused";
    } else {
      return className + " swap-hidden";
    }
  };

  return (
    <button
        ref={buttonRef}
        tabIndex={0}
        aria-label={"Swap " + accessibleShortName + " " + direction}
        className={getClassName()}
        onClick={onSwap}>
      <svg
        className="Arrow"
        viewBox="0 0 36 36"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#fff"
          stroke="#666"
          strokeWidth={1}
          d="M 2,12 L 18,12 L 18,2 L 33,17 L 18,32 L 18,22 L 2,22 Z"
        />
      </svg>
    </button>
  );
};

export default Swap;
