import "./BasePairMatch.css";

interface BasePairMatchProps {
  match: boolean;
}

const BasePairMatch = ({ match }: BasePairMatchProps) => {
  if (match) {
    return (
      <>
        <div role="none" className="BasePairMatch BasePairMatchLeft"></div>
        <div role="none" className="BasePairMatch BasePairMatchCenter"></div>
        <div role="none" className="BasePairMatch BasePairMatchRight"></div>
      </>
    );
  } else {
    return (
      <>
        <div role="none" className="BasePairMatch BasePairMatchLeft"></div>
        <div role="none" className="BasePairMatch BasePairMatchEmpty"></div>
        <div role="none" className="BasePairMatch BasePairMatchRight"></div>
      </>
    );
  }
};

export default BasePairMatch;
