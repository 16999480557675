import React, { useEffect, useRef } from "react";
import BasePairMatch from "./BasePairMatch";
import Base from "./Base";
import * as common from "./Common";
import "./BasePair.css";
import rotate_cw_icon from './images/rotate_cw_icon.png';
import rotate_ccw_icon from './images/rotate_ccw_icon.png';

interface BasePairProps {
  left: string;
  right: string;
  rowIndex: number;
  focused?: string;
  focusMode: boolean;
  arrowMode: boolean;
  dx: number;
  dy: number;
  canFocus: boolean;
  canSwapUp: boolean;
  canSwapDown: boolean;
  canRotateCW: boolean;
  canRotateCCW: boolean;
  leftAccessibleName: string;
  leftAccessibleShortName: string;
  rightAccessibleName: string;
  rightAccessibleShortName: string;
  solved: boolean;
  narrow?: boolean;
  didFocus: (direction: string) => void;
  didClick: (direction: string) => void;
  didSwapLeftRight: () => void;
  didSwapUp: (direction: string) => void;
  didSwapDown: (direction: string) => void;
  didRotateCW: () => void;
  didRotateCCW: () => void;
  onPointerDown: (direction: string, e: React.PointerEvent<HTMLElement>) => void;
}

const BasePair = ({
  left,
  right,
  rowIndex,
  didFocus,
  didClick,
  focused,
  focusMode,
  arrowMode,
  dx,
  dy,
  canFocus,
  canSwapUp,
  canSwapDown,
  canRotateCW,
  canRotateCCW,
  leftAccessibleName,
  leftAccessibleShortName,
  rightAccessibleName,
  rightAccessibleShortName,
  solved,
  narrow,
  didSwapLeftRight,
  didSwapUp,
  didSwapDown,
  didRotateCW,
  didRotateCCW,
  onPointerDown
}: BasePairProps) => {
  let ccwRef = useRef<HTMLButtonElement>(null);
  let cwRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focused === "cw" && cwRef.current && document.activeElement !== cwRef.current) {
      cwRef.current?.focus();
    }
    if (focused === "ccw" && ccwRef.current && document.activeElement !== ccwRef.current) {
      ccwRef.current?.focus();
    }
  });

  return (
    <>
      <div className="BasePairAndButtonsWrapper">
        <div className={`BasePairWrapper ${
                  narrow ? "Narrow" : ""
                }`}>
          <Base
            codon={left}
            focused={focused === "left"}
            focusMode={focusMode}
            arrowMode={arrowMode}
            dx={solved ? 18 : focused === "left" ? dx : 0}
            dy={focused === "left" ? dy : 0}
            didFocus={() => didFocus("left")}
            didClick={() => didClick("left")}
            canFocus={canFocus}
            canSwapUp={canSwapUp}
            canSwapDown={canSwapDown}
            canSwapLeft={false}
            canSwapRight={true}
            rotated={false}
            solved={solved}
            accessibleName={leftAccessibleName}
            accessibleShortName={leftAccessibleShortName}
            didSwapLeftRight={didSwapLeftRight}
            didSwapUp={() => didSwapUp("left")}
            didSwapDown={() => didSwapDown("left")}
            onPointerDown={(e) => onPointerDown("left", e)}
          />
          {solved || <BasePairMatch match={common.isMatch(left, right)} />}
          <Base
            codon={right}
            focused={focused === "right"}
            focusMode={focusMode}
            arrowMode={arrowMode}
            didFocus={() => didFocus("right")}
            didClick={() => didClick("right")}
            dx={solved ? -18 : focused === "right" ? dx : 0}
            dy={focused === "right" ? dy : 0}
            canFocus={canFocus}
            canSwapUp={canSwapUp}
            canSwapDown={canSwapDown}
            canSwapLeft={true}
            canSwapRight={false}
            rotated={true}
            solved={solved}
            accessibleName={rightAccessibleName}
            accessibleShortName={rightAccessibleShortName}
            didSwapLeftRight={didSwapLeftRight}
            didSwapUp={() => didSwapUp("right")}
            didSwapDown={() => didSwapDown("right")}
            onPointerDown={(e) => onPointerDown("right", e)}
          />
        </div>
        {canSwapDown && (canRotateCCW || canRotateCW) && (
          <div className={`RotateWrapper ${
            narrow ? "Narrow" : ""
          }`}>
            {canRotateCCW && (
              <button
                className={`RotateButton CCW ${
                  solved ? "Solved" : ""
                }`}
                aria-label={`Rotate rows ${rowIndex + 1} and ${rowIndex + 2} counter-clockwise`}
                ref={ccwRef}
                onClick={didRotateCCW}
                onFocus={() => didFocus("ccw")}
              >
                <img src={rotate_ccw_icon} alt=""></img>
              </button>
            )}
            {canRotateCW && (
              <button
                className={`RotateButton CW ${
                  solved ? "Solved" : ""
                }`}
                aria-label={`Rotate rows ${rowIndex + 1} and ${rowIndex + 2} clockwise`}
                ref={cwRef}
                onClick={didRotateCW}
                onFocus={() => didFocus("cw")}
              >
                <img src={rotate_cw_icon} alt=""></img>
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BasePair;
