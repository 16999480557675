import React, { useRef, useState, useEffect } from "react";
import Swap from "./Swap";
import { Flipped } from "react-flip-toolkit";
import "./Base.css";

interface BaseProps {
  codon: string;
  focused: boolean;
  focusMode: boolean;
  arrowMode: boolean;
  dx: number;
  dy: number;
  canFocus: boolean;
  canSwapUp: boolean;
  canSwapDown: boolean;
  canSwapLeft: boolean;
  canSwapRight: boolean;
  accessibleName: string;
  accessibleShortName: string;
  rotated: boolean;
  solved: boolean;
  didFocus: () => void;
  didClick: () => void;
  didSwapLeftRight: () => void;
  didSwapUp: () => void;
  didSwapDown: () => void;
  onPointerDown: (e: React.PointerEvent<HTMLElement>) => void;
}

const Base = ({
  codon,
  focused,
  focusMode,
  arrowMode,
  dx,
  dy,
  canFocus,
  canSwapUp,
  canSwapDown,
  canSwapLeft,
  canSwapRight,
  accessibleName,
  accessibleShortName,
  rotated,
  solved,
  didFocus,
  didClick,
  didSwapLeftRight,
  didSwapUp,
  didSwapDown,
  onPointerDown
}: BaseProps) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 10);
  }, []);

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (focused && document.activeElement !== divRef.current) {
      divRef.current?.focus();
    }
  });

  const onSwapLeftRight = (e: React.MouseEvent<HTMLElement>) => {
      didSwapLeftRight();
      e.stopPropagation();
      e.preventDefault();
  };

  const onSwapUp = (e: React.MouseEvent<HTMLElement>) => {
    didSwapUp();
    e.stopPropagation();
    e.preventDefault();
  };

  const onSwapDown = (e: React.MouseEvent<HTMLElement>) => {
    didSwapDown();
    e.stopPropagation();
    e.preventDefault();
  };

  const shapePath = (codon: string) => {
    switch (codon.substr(0, 1)) {
      case "A":
        return "M 6,2 L 54,2 L 70,18 L 54,34 L 6,34 Q 2,34,2,30 L 2,6 Q 2,2,6,2 Z";
      case "C":
        return "M 2,2 L 54,2 Q 66,6,66,18 Q 66,30,54,34 L 6,34 Q 2,34,2,30 L 2,6 Q 2,2,6,2 Z";
      case "G":
        return "M 2,2 L 54,2 Q 42,6,42,18 Q 42,30,54,34 L 6,34 Q 2,34,2,30 L 2,6 Q 2,2,6,2 Z";
      case "T":
        return "M 2,2 L 54,2 L 38,18 L 54,34 L 6,34 Q 2,34,2,30 L 2,6 Q 2,2,6,2 Z";
    }
  }

  let getBaseShapeClassName = () => {
    let c = "ShapeWrapper";
    if (rotated) {
      c += " Rotated";
    } else {
      c += " NonRotated";
    }
    if (visible) {
      c += " Visible";
    }
    return c;
  }

  return (
    <div className="BaseWrapper">
      <Flipped key={codon} flipId={codon} stagger={solved ? true : undefined}>
        <div
          role="img"
          aria-roledescription="Base"
          aria-label={accessibleName}
          ref={divRef}
          tabIndex={canFocus ? 0 : undefined}
          className={`Base ${codon.substr(0, 1)} ${(focused && focusMode) ? "focused" : ""}`}
          onFocus={() => didFocus()}
          onPointerDown={onPointerDown}
          onClick={() => didClick()}
          style={{
            left: dx + "px",
            top: dy + "px"
          }}
        >
          <div className={getBaseShapeClassName()}>
            <svg
              className="BaseShape"
              viewBox="0 0 72 36"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                filter="url(#ShapeFilter)"
                fill="#2edede"
                stroke="#fff"
                strokeWidth={0}
                d={shapePath(codon)}
              />
            </svg>
            <div className="ShapeLabel">{codon.substr(0, 1)}</div>
          </div>
        </div>
      </Flipped>
      <span className="swapparent" role="none">
        {canSwapLeft && <Swap
            accessibleShortName={accessibleShortName}
            direction="left"
            visible={focused && arrowMode}
            onSwap={onSwapLeftRight}
          />}
        {canSwapRight && <Swap
            accessibleShortName={accessibleShortName}
            direction="right"
            visible={focused && arrowMode}
            onSwap={onSwapLeftRight}
          />}
        {canSwapDown && <Swap
            accessibleShortName={accessibleShortName}
            direction="down"
            visible={focused && arrowMode}
            onSwap={onSwapDown}
          />}
        {canSwapUp && <Swap
            accessibleShortName={accessibleShortName}
            direction="up"
            visible={focused && arrowMode}
            onSwap={onSwapUp}
          />}
      </span>
    </div>
  );
};

export default Base;
