import BasePair from "./BasePair";
import React, { useState, useEffect, useRef } from "react";
import StaticBasePair from "./StaticBasePair";
import { Flipper } from "react-flip-toolkit";
import * as common from "./Common";
import "./Game.css";
import home_icon from "./images/home_icon.png";
import help_icon from "./images/help_icon.png";
import reload_icon from "./images/reload_icon.png";

interface GameProps {
  clientId: string;
  puzzle: string;
  requiredMoves: number;
  level: string;
  closing: boolean;
  codon: string;
  prompt: string;
  bottomPrompt: string;
  allowRotate: boolean;
  onHome: () => void;
  onSolve: () => void;
}

const buildGridFromPuzzleString = (puzzleString: string) => {
  let result = [];
  let index = 0;
  while (puzzleString.length > 0) {
    let left = puzzleString.substr(0, 1);
    let right = puzzleString.substr(1, 1);
    result.push({ left: left + index, right: right + (index + 1) });
    puzzleString = puzzleString.substr(2);
    index += 2;
  }
  return result;
};

// The last ones in the list are increasingly rare.
const funnyMessages = [
  "Great!",
  "DNA++ job!",
  "Double helix, double awesome!",
  "You figured out the twist!",
  "Climb the spiral staircase of success!",
  "Let's cell-abrate!",
  "Way to cell it!",
  "Bring it on like a codon!",
  "Dominant performance!",
  "This win isn't thy-mine, it's thy-yours!",
  "The name is Bond. Covalent Bond.",
  "You're the zyGOAT - the Greatest Of All Time!",
  "That was based.",
  "So, Mr. Bond...we gamete again.",
  "Meiosis? No, YOUR Osis.",
  "ChromoSOMEbody once told me the world is gonna roll me...",
];

const Game = ({
  clientId,
  puzzle,
  requiredMoves,
  level,
  closing,
  codon,
  prompt,
  bottomPrompt,
  allowRotate,
  onHome,
  onSolve,
}: GameProps) => {
  const initialGame = buildGridFromPuzzleString(puzzle);

  const [focusMode, setFocusMode] = useState(false);
  const [arrowMode, setArrowMode] = useState(false);
  const [focusedRow, setFocusedRow] = useState(0);
  const [focusedSide, setFocusedSide] = useState("left");
  const [prevFocusedRow, setPrevFocusedRow] = useState(0);
  const [prevFocusedSide, setPrevFocusedSide] = useState("left");
  const [rows, setRows] = useState(initialGame);

  const [moveCount, setMoveCount] = useState(0);
  const [justSolved, setJustSolved] = useState(false);
  const [solved, setSolved] = useState(false);
  const [message, setMessage] = useState("");
  const [bottomMessage, setBottomMessage] = useState("");
  const [messageFading, setMessageFading] = useState(false);
  const [bottomMessageFading, setBottomMessageFading] = useState(false);
  const [showingHelp, setShowingHelp] = useState(false);
  const [helpFadedIn, setHelpFadedIn] = useState(false);

  const [opening, setOpening] = useState(true);

  const closeDialogRef = useRef<HTMLButtonElement>(null);

  const reportAction = (action: string) => {
    let actionData = {
      level: level,
      puzzle: puzzle,
      codon: codon,
      swap_count: requiredMoves,
      has_rotate: allowRotate,
      action: action,
      client_id: clientId
    };
    fetch("/action/report", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(actionData)
    });
  };

  useEffect(() => {
    reportAction('play');

    setTimeout(() => {
      setOpening(false);
    }, 1);

    if (prompt && prompt.length) {
      setTimeout(() => {
        setMessage(prompt);
      }, 1000);
    }
    if (bottomPrompt && bottomPrompt.length) {
      setTimeout(() => {
        setBottomMessage(bottomPrompt);
      }, 2000);
    }
    if (level === "1") {
      setTimeout(() => {
        showHelp();
      }, 500);
    }
  }, []);

  // Dragging
  const [dx, setDx] = useState(0);
  const [dy, setDy] = useState(0);
  const [startx, setStartX] = useState(0);
  const [starty, setStartY] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [dragRow, setDragRow] = useState(0);
  const [dragSide, setDragSide] = useState("left");

  const setRowsAndIncreaseMoveCount = (
    newRows: { left: string; right: string }[]
  ) => {
    reportAction("move" + (moveCount + 1));
    setRows(newRows);
    setMoveCount(moveCount + 1);
  };

  const resetGame = () => {
    setRows(initialGame);
    setMoveCount(0);
    setFocusedRow(0);
    setFocusedSide("left");
    setFocusMode(false);
    setArrowMode(false);
    setSolved(false);
    setJustSolved(false);
    setShowingHelp(false);
    setHelpFadedIn(false);
  };

  const swapDown = (row: number, side: string) => {
    const newRows = JSON.parse(JSON.stringify(rows));
    const tmp = newRows[row][side];
    newRows[row][side] = newRows[row + 1][side];
    newRows[row + 1][side] = tmp;
    setRowsAndIncreaseMoveCount(newRows);
  };

  const swapAcross = (row: number) => {
    const newRows = JSON.parse(JSON.stringify(rows));
    const tmp = newRows[row].left;
    newRows[row].left = newRows[row].right;
    newRows[row].right = tmp;
    setRowsAndIncreaseMoveCount(newRows);
  };

  const rotateCCW = (row: number) => {
    const newRows = JSON.parse(JSON.stringify(rows));
    newRows[row].left = rows[row].right;
    newRows[row].right = rows[row + 1].right;
    newRows[row + 1].right = rows[row + 1].left;
    newRows[row + 1].left = rows[row].left;
    setRowsAndIncreaseMoveCount(newRows);
  };

  const rotateCW = (row: number) => {
    const newRows = JSON.parse(JSON.stringify(rows));
    newRows[row].right = rows[row].left;
    newRows[row + 1].right = rows[row].right;
    newRows[row + 1].left = rows[row + 1].right;
    newRows[row].left = rows[row + 1].left;
    setRowsAndIncreaseMoveCount(newRows);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    setFocusMode(true);

    let baseFocused = (focusedSide == "left" || focusedSide == "right");
    let rotateFocused = (focusedSide == "ccw" || focusedSide == "cw");
    let firstRow = focusedRow == 0;
    let lastRow = focusedRow >= rows.length - 1;

    if (e.key === " " && baseFocused) {
      setArrowMode(true);
    }

    if (e.key === "ArrowDown" && !lastRow) {
      if (e.shiftKey && baseFocused) {
        swapDown(focusedRow, focusedSide);
      }
      if (allowRotate) {
        if (focusedSide == "right") {
          setFocusedSide("cw");
        } else if (focusedSide == "left") {
          setFocusedSide("ccw");
        } else if (focusedSide == "ccw" && !lastRow) {
          setFocusedRow(focusedRow + 1);
          setFocusedSide("left");
        } else if (focusedSide == "cw" && !lastRow) {
          setFocusedRow(focusedRow + 1);
          setFocusedSide("right");
        }
      } else {
        setFocusedRow(focusedRow + 1);
      }
    }
    if (e.key === "ArrowUp") {
      if (e.shiftKey && baseFocused) {
        swapDown(focusedRow - 1, focusedSide);
      }
      if (allowRotate) {
        if (focusedSide == "right" && !firstRow) {
          setFocusedRow(focusedRow - 1);
          setFocusedSide("cw");
        } else if (focusedSide == "left") {
          setFocusedRow(focusedRow - 1);
          setFocusedSide("ccw");
        } else if (focusedSide == "ccw") {
          setFocusedSide("left");
        } else if (focusedSide == "cw") {
          setFocusedSide("right");
        }
      } else if (!firstRow) {
        setFocusedRow(focusedRow - 1);
      }
    }
    if (e.key === "ArrowRight") {
      if (e.shiftKey && focusedSide === "left") {
        swapAcross(focusedRow);
      }
      if (focusedSide === "left") {
        setFocusedSide("right");
      } else if (focusedSide === "ccw") {
        setFocusedSide("cw");
      }
    }
    if (e.key === "ArrowLeft") {
      if (e.shiftKey && focusedSide === "right") {
        swapAcross(focusedRow);
      }
      if (focusedSide === "right") {
        setFocusedSide("left");
      } else if (focusedSide === "cw") {
        setFocusedSide("ccw");
      }
    }
    if (e.key === "Escape") {
      clearFocus();
    }
  };

  useEffect(() => {
    if (focusedRow != prevFocusedRow || focusedSide != prevFocusedSide) {
      setPrevFocusedRow(focusedRow);
      setPrevFocusedSide(focusedSide);
      setArrowMode(false);
    }
  });

  const clearFocus = () => {
    setTimeout(() => {
      setFocusedSide("");
      setFocusedRow(-1);
    }, 10);
  };

  const onBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    setTimeout(() => {
      let e = document.activeElement;
      let inGame = false;
      while (e && e !== document.body) {
        if (e.classList.contains("Game")) {
          inGame = true;
          break;
        }
        e = e.parentElement;
      }
      if (!inGame) {
        setFocusedSide("");
        setFocusedRow(-1);
      }
    }, 200);
  };

  const didFocus = (side: string, row: number) => {
    setFocusedSide(side);
    setFocusedRow(row);
  };

  const onPointerDown = (
    rowIndex: number,
    side: string,
    e: React.PointerEvent<HTMLElement>
  ) => {
    setDragging(true);
    setStartX(e.clientX);
    setStartY(e.clientY);
    setStartTime(new Date().getTime());
    setDragRow(rowIndex);
    setDragSide(side);
    setFocusedSide(side);
    setFocusedRow(rowIndex);
  };

  const onPointerMove = (e: React.PointerEvent<HTMLElement>) => {
    if (dragging) {
      setArrowMode(false);
      setFocusMode(false);
      setDx(e.clientX - startx);
      setDy(e.clientY - starty);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const onPointerUp = (e: React.PointerEvent<HTMLElement>) => {
    let deltaTime = new Date().getTime() - startTime;
    let deltaPosSquared = dx * dx + dy * dy;
    if (deltaTime < 500 && deltaPosSquared < 100) {
      setPrevFocusedSide(focusedSide);
      setPrevFocusedRow(focusedRow);
      setArrowMode(true);
      setFocusMode(false);
    }

    if (dragging) {
      setDragging(false);
      if (dragSide === "left" && dx > 64) {
        swapAcross(dragRow);
      } else if (dragSide === "right" && dx < -64) {
        swapAcross(dragRow);
      } else if (dy > 32 && dragRow < rows.length - 1) {
        swapDown(dragRow, dragSide);
      } else if (dy < -32 && dragRow > 0) {
        swapDown(dragRow - 1, dragSide);
      }
    }
    setDx(0);
    setDy(0);
  };

  const didClick = (side: string, row: number) => {
    setFocusedSide(side);
    setFocusedRow(row);
    setPrevFocusedSide(side);
    setPrevFocusedRow(row);
    setFocusMode(true);
    setArrowMode(true);
  };

  const computeSolved = () => {
    let correct = 0;
    let seq = '';
    for (let i = 0; i < rows.length; i++) {
      seq += rows[i].left[0];
      if (common.isMatch(rows[i].left, rows[i].right)) {
        correct++;
      }
    }
    return correct === rows.length && seq.includes(codon);
  };

  const accessibleName = (rowIndex: number, side: string) => {
    return (
      (side === "right"
        ? rows[rowIndex].right.substr(0, 1)
        : rows[rowIndex].left.substr(0, 1)) +
      ", row " +
      (rowIndex + 1) +
      ", " +
      side +
      (common.isMatch(rows[rowIndex].left, rows[rowIndex].right)
        ? ", match"
        : ", not a match")
    );
  };

  const displayMessage = (newMessage: string) => {
    setMessage(newMessage);
    setMessageFading(false);
    setTimeout(() => {
      if (message === newMessage) {
        setMessageFading(true);
      }
    }, 5000);
    setTimeout(() => {
      if (message === newMessage) {
        setMessage("");
      }
    }, 6000);
  };

  useEffect(() => {
    if (!solved && !justSolved && computeSolved()) {
      setJustSolved(true);
      reportAction('solve');
      clearFocus();
      let messageToDisplay;
      if (level === "1" || level === "2" || level === "3" || level === "4" || level === "5"
          || level === "easy") {
        messageToDisplay = "Solved!";
      } else {
        // Bias towards lower messages.
        let r = Math.random();
        let messageIndex = Math.floor((r * r) * funnyMessages.length);
        messageToDisplay = funnyMessages[messageIndex];
      }
      displayMessage(messageToDisplay);
      setTimeout(() => {
        setSolved(true);
      }, 1000);
      setTimeout(() => {
        onSolve();
      }, 3000);
    } else if (requiredMoves > 0 && moveCount === requiredMoves && !computeSolved()) {
      reportAction("fail");
      displayMessage("Sorry!");
      setTimeout(() => {
        resetGame();
      }, 1000);
    }
  });

  const hideHelp = () => {
    setTimeout(() => {
      let help = document.getElementById("help");
      help?.animate([ { opacity: 1 }, { opacity: 0 } ], 500)
      .finished.then(() => {
        help?.classList.remove('Visible');
        setShowingHelp(false);
        setHelpFadedIn(false);
      });
    }, 0);
  };

  const help = () => {
    if (showingHelp) {
      return (
        <>
          <div className="HelpWrapper">
            <div id="help" className="HelpBackground">
              <div
                className="HelpDialog"
                aria-label="Help"
                role="dialog"
                aria-modal="true"
              >
                <div className="CloseXWrapper">
                  <button
                    ref={closeDialogRef}
                    className="CloseX"
                    onClick={hideHelp}
                    aria-label="Close Help Dialog"
                  >
                    X
                  </button>
                </div>
                <div className="CenterWrapper">
                  <h2>How to Play</h2>
                </div>
                <h3>DNA</h3>
                <p>DNA is made up of very long sequences of 4 "bases":</p>
                <div className="CenterWrapper">
                  <div className="DNABaseWrapper">
                    <div className="DNABase">
                      <div className="CodonGoal A">
                        <span>A</span>
                      </div>
                      <div>Adenine</div>
                    </div>
                    <div className="DNABase">
                      <div className="CodonGoal C">
                        <span>C</span>
                      </div>
                      <div>Cytosine</div>
                    </div>
                    <div className="DNABase">
                      <div className="CodonGoal G">
                        <span>G</span>
                      </div>
                      <div>Guanine</div>
                    </div>
                    <div className="DNABase">
                      <div className="CodonGoal T">
                        <span>T</span>
                      </div>
                      <div>Thymine</div>
                    </div>
                  </div>
                </div>
                <h3>Matching</h3>
                <p>
                  A valid solution to the puzzle must always result in a correct
                  pairing of the DNA bases. A only pairs with T, and C only
                  pairs with G.
                </p>
                <StaticBasePair left={"A"} right={"T"} />
                <StaticBasePair left={"G"} right={"C"} />
                <p>
                  A broken line indicates that two DNA bases are not currently
                  matched:
                </p>
                <StaticBasePair left={"C"} right={"T"} />
                <h3>Valid Moves</h3>
                <p>
                  You can swap any DNA base with its immediate neighbor to the
                  left, right, top, or bottom. Click, swipe, or use the
                  keyboard.
                </p>
                {allowRotate ? (
                  <>
                    <p>
                      You can also rotate two rows of base pairs either
                      clockwise or counterclockwise by pressing the rotate
                      buttons in-between each pair of rows. That only counts as
                      one move.
                    </p>
                    <StaticBasePair left={"C"} right={"A"} canRotate={true} />
                    <StaticBasePair left={"G"} right={"T"} />
                  </>
                ) : (
                  <></>
                )}
                {requiredMoves != 0 ? (
                  <>
                    <h3>Required moves</h3>
                    <p>
                      You have to solve this puzzle in exactly {requiredMoves}{" "}
                      moves. You can see the number of moves you've made so far,
                      and the total number of allowed moves, at the top of the
                      screen.
                    </p>
                  </>
                ) : (
                  <></>
                )}
                {codon != "" ? (
                  <>
                    <h3>Codon</h3>
                    <p>
                      A codon is a sequence of exactly 3 DNA bases. This puzzle
                      is a Codon Challenge: you have to not only match up all of
                      the DNA base pairs correctly, you also have to do so in
                      such a way that includes a particular codon in the result.
                    </p>
                    <p>This puzzle's codon is:</p>
                    <div className="CenterWrapper">
                      <div className={`CodonGoal ${codon[0]}`}>
                        <span>{codon[0]}</span>
                      </div>
                      <div className={`CodonGoal ${codon[1]}`}>
                        <span>{codon[1]}</span>
                      </div>
                      <div className={`CodonGoal ${codon[2]}`}>
                        <span>{codon[2]}</span>
                      </div>
                    </div>
                    <p>
                      You can see the required codon along the left side of the
                      game. The solution must include that sequence of DNA bases
                      along the left side, in exactly that order from top to
                      bottom.
                    </p>
                    <p>
                      The codon can appear anywhere in the result, as long as it
                      appears in order from top to bottom. In other words, it
                      doesn't matter if the codon is at the beginning, middle,
                      or the end.
                    </p>
                  </>
                ) : (
                  <></>
                )}
                <div className="CloseWrapper">
                  <button onClick={hideHelp}>Let's Play!</button>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const showHelp = () => {
    if (showingHelp) {
      reportAction('showHelp');
      hideHelp();
      return;
    }
    reportAction('hideHelp');
    setShowingHelp(true);
  }

  useEffect(() => {
    if (showingHelp && !helpFadedIn) {
      setHelpFadedIn(true);
      let help = document.getElementById("help");
      let a = help?.animate([ { opacity: 0 }, { opacity: 1 } ], 500)
    .finished.then(() => {  
        help?.classList.add('Visible');
        closeDialogRef.current?.focus();
      });
    }
  });

  return (
    <div
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      className={`Game ${opening ? "Opening" : closing ? "Closing" : ""}`}
    >
      {help()}
      <div className="TopArea" tabIndex={0}>
        <div className="ValueWrapper">
          <div className="ValueLabel">Level</div>
          <div className="Value">{level}</div>
        </div>
        <div className="ValueWrapper">
          <div className="ValueLabel">Moves</div>
          <div className="Value">
            {moveCount}
            {requiredMoves > 0 ? "/" : ""}
            {requiredMoves > 0 ? requiredMoves : ""}
          </div>
        </div>
      </div>
      <div className="MessageArea" aria-live="polite" tabIndex={message.length > 0 ? 0 : -1}>
        <div
          className={`Message ${
            message.length > 0 && !messageFading ? "Visible" : "Hidden"
          }`}
        >
          {message}
        </div>
      </div>
      {codon ? (
        <div className="CodonWrapper" tabIndex={0}>
          <div className="CodonLabel">Codon</div>
          <div className="CodonGoalWrapper">
            <div className={`CodonGoal ${codon[0]}`}>
              <span>{codon[0]}</span>
            </div>
            <div className={`CodonGoal ${codon[1]}`}>
              <span>{codon[1]}</span>
            </div>
            <div className={`CodonGoal ${codon[2]}`}>
              <span>{codon[2]}</span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div role="application"
        className="PlayArea"
        onPointerMove={onPointerMove}
        onPointerUp={onPointerUp}
      >
        <Flipper flipKey={JSON.stringify(rows) + solved} spring="wobbly">
          {rows.map((row, rowIndex) => (
            <BasePair
              key={rowIndex}
              rowIndex={rowIndex}
              left={row.left}
              right={row.right}
              focusMode={focusMode}
              arrowMode={arrowMode}
              focused={focusedRow === rowIndex ? focusedSide : undefined}
              dx={focusedRow === rowIndex && dragging ? dx : 0}
              dy={focusedRow === rowIndex && dragging ? dy : 0}
              canFocus={!showingHelp}
              canSwapUp={rowIndex > 0}
              canSwapDown={rowIndex < rows.length - 1}
              canRotateCCW={allowRotate}
              canRotateCW={allowRotate}
              leftAccessibleName={accessibleName(rowIndex, "left")}
              leftAccessibleShortName={row.left.substr(0, 1)}
              rightAccessibleName={accessibleName(rowIndex, "right")}
              rightAccessibleShortName={row.right.substr(0, 1)}
              solved={solved}
              didFocus={(side) => didFocus(side, rowIndex)}
              didClick={(side) => didClick(side, rowIndex)}
              didSwapLeftRight={() => {
                swapAcross(rowIndex);
                clearFocus();
              }}
              didSwapUp={(side) => {
                swapDown(rowIndex - 1, side);
                clearFocus();
              }}
              didSwapDown={(side) => {
                swapDown(rowIndex, side);
                clearFocus();
              }}
              didRotateCCW={() => {
                rotateCCW(rowIndex);
                clearFocus();
              }}
              didRotateCW={() => {
                rotateCW(rowIndex);
                clearFocus();
              }}
              onPointerDown={(side, e) => onPointerDown(rowIndex, side, e)}
            />
          ))}
        </Flipper>
      </div>
      <div className="BottomSpacer">
        <div className="MessageArea" aria-live="polite">
          <div
            className={`Message ${
              bottomMessage.length > 0 && !bottomMessageFading
                ? "Visible"
                : "Hidden"
            }`}
          >
            {bottomMessage}
          </div>
        </div>
      </div>
      <div className="BottomArea">
        <div className="ButtonWrapper">
          <button
            className="IconButton Home"
            onClick={() => {
              reportAction("home");
              onHome();
            }}
            disabled={showingHelp}
          >
            <img src={home_icon} alt="Home"></img>
          </button>
          <span aria-hidden="true">Home</span>
        </div>
        <div className="ButtonWrapper">
          <button
            className="IconButton Reset"
            onClick={() => {
              reportAction("reset");
              resetGame();
            }}
            disabled={showingHelp}
          >
            <img src={reload_icon} alt="Reset"></img>
          </button>
          <span aria-hidden="true">Reset</span>
        </div>
        <div className="ButtonWrapper">
          <button
            className="IconButton Help"
            onClick={showHelp}
            disabled={showingHelp}
          >
            <img src={help_icon} alt="Help"></img>
          </button>
          <span aria-hidden="true">Help</span>
        </div>
      </div>
    </div>
  );
};

export default Game;
