export function isMatch(left: string, right: string): boolean {
  let l = left.substr(0, 1);
  let r = right.substr(0, 1);
  return (
    (l === "A" && r === "T") ||
    (l === "C" && r === "G") ||
    (l === "G" && r === "C") ||
    (l === "T" && r === "A")
  );
}

declare global {
  interface Window {
      chrome:any;
  }
}

declare const InstallTrigger: any;

// Based on ideas from this StackOverflow answer by Rob W
// https://stackoverflow.com/questions/9847580/
export function browserSupportsDoubleHelixAnimation() {
  // Chrome and specifically Blink engine (not Chrome on iOS) works:
  if (!!window.chrome && !!window.CSS) {
    return true;
  }

  // Firefox works:
  if (typeof InstallTrigger !== 'undefined') {
    return true;
  }

  // Safari and older browsers: no
}
