import Game from "./Game";
import { useState, useEffect } from "react";
import "./App.css";
import logo from "./images/logo.png";
import check4_icon from "./images/check4_icon.png";
import * as common from "./Common";

let check_icon = check4_icon;

interface Puzzle {
  level: number;
  levelName?: string;
  puzzle: string;
  codon?: string;
  requiredMoves?: number;
  prompt?: string;
  bottomPrompt?: string;
  allowRotate?: boolean;
  noKits?: boolean;
}

interface PuzzleGroup {
  groupLabel: string;
  puzzles: Puzzle[];
}

const puzzleGroups: PuzzleGroup[] = [
  {
    groupLabel: "Tutorial",
    puzzles: [
      {
        level: 1,
        puzzle: "AGCT",
        prompt:
          "Try swapping the A, C, G, or T with one of its neighbors " +
          "so that A pairs with T and C pairs with G.",
        noKits: true,
      },
      {
        level: 2,
        puzzle: "TCAG",
        prompt: "Keep swapping bases until everything pairs",
        noKits: true,
      },
    ],
  },
  {
    groupLabel: "Warm Ups",
    puzzles: [
      {
        level: 3,
        puzzle: "TGAACT",
      },
      {
        level: 4,
        puzzle: "TGCCGA",
      },
      {
        level: 5,
        puzzle: "CAATTG",
      },
    ],
  },
  {
    groupLabel: "3 moves",
    puzzles: [
      {
        level: 6,
        puzzle: "GAGTCC",
        requiredMoves: 3,
        prompt: "Can you solve the puzzle in exactly 3 moves?",
      },
      {
        level: 7,
        puzzle: "TGAATC",
        requiredMoves: 3,
      },
      {
        level: 8,
        puzzle: "TCCGAG",
        requiredMoves: 3,
      },
      {
        level: 9,
        puzzle: "TTAGAC",
        requiredMoves: 3,
      },
      {
        level: 10,
        puzzle: "TGTAAC",
        requiredMoves: 3,
      },
      {
        level: 11,
        puzzle: "CCTAGG",
        requiredMoves: 3,
      },
      {
        level: 12,
        puzzle: "ACCGGT",
        requiredMoves: 3,
      },
    ],
  },
  {
    groupLabel: "4 moves",
    puzzles: [
      {
        level: 13,
        puzzle: "TCAGGACT",
        requiredMoves: 4,
        prompt: "Look out: this one requires 4 moves!",
      },
      {
        level: 14,
        puzzle: "ACTTTGAA",
        requiredMoves: 4,
      },
      {
        level: 15,
        puzzle: "GGCTGCCA",
        requiredMoves: 4,
      },
      {
        level: 16,
        puzzle: "AAGCTATT",
        requiredMoves: 4,
      },
    ],
  },
  {
    groupLabel: "Codons",
    puzzles: [
      {
        level: 17,
        puzzle: "TCAGACTG",
        requiredMoves: 4,
        codon: "GCT",
        prompt:
          "Codon challenge: the solution must contain GCT (Alanine) going down the left column",
      },
      {
        level: 18,
        puzzle: "ACGGTACT",
        requiredMoves: 4,
        codon: "AGA",
        prompt:
          "Codon challenge: the solution must contain AGA (Arginine)",
      },
      {
        level: 19,
        puzzle: "TTCATAAG",
        requiredMoves: 4,
        codon: "ATA",
        prompt:
          "Codon challenge: the solution must contain ATA (Isoleucine)",
      },
      {
        level: 20,
        puzzle: "AGGCTGCC",
        requiredMoves: 4,
        codon: "CAC",
        prompt:
          "Codon challenge: the solution must contain CAC (Histidine)",
      }
    ],
  },
  {
    groupLabel: "Rotation",
    puzzles: [
      {
        level: 21,
        puzzle: "TGAC",
        requiredMoves: 1,
        allowRotate: true,
        prompt:
          "Use one of the rotation buttons to solve this puzzle in just one move.",
      },
      {
        level: 22,
        puzzle: "GTCGAC",
        requiredMoves: 2,
        allowRotate: true,
        prompt:
          "Solve this in two moves using one rotation and one swap.",
      },
      {
        level: 23,
        puzzle: "TCCGGA",
        requiredMoves: 2,
        allowRotate: true,
        prompt:
          "Solve this in two moves using one rotation and one swap.",
      },
      {
        level: 24,
        puzzle: "CAGGCT",
        requiredMoves: 2,
        allowRotate: true,
        prompt:
          "Solve this using two rotations.",
      },
      {
        level: 25,
        puzzle: "AATATT",
        requiredMoves: 2,
        allowRotate: true,
        prompt:
          "Solve this using two rotations.",
      },
    ],
  },
  {
    groupLabel: "Rotation Part 2",
    puzzles: [
      {
        level: 26,
        puzzle: "GTCCTAAG",
        requiredMoves: 3,
        allowRotate: true,
        prompt:
          "Use rotations and swaps to solve this in 3 total moves.",
      },
      {
        level: 27,
        puzzle: "GACTGTAATC",
        requiredMoves: 3,
        allowRotate: true,
        prompt:
          "Use rotations and swaps to solve this in 3 total moves.",
      },
      {
        level: 28,
        puzzle: "CTGAAGTACT",
        requiredMoves: 3,
        allowRotate: true,
        prompt:
          "Use rotations and swaps to solve this in 3 total moves.",
      },
      {
        level: 29,
        puzzle: "AGTCGATATC",
        requiredMoves: 3,
        allowRotate: true,
        prompt:
          "Use rotations and swaps to solve this in 3 total moves.",
      },
      {
        level: 30,
        puzzle: "TCGGACCCGG",
        requiredMoves: 3,
        codon: "GGG",
        allowRotate: true,
        prompt:
          "Codon challenge: the solution must contain GGG.",
      },
    ]
  },
  {
    groupLabel: "Larger puzzles",
    puzzles: [
      {
        level: 31,
        puzzle: "CAGGTCGGATCC",
        requiredMoves: 5,
      },
      {
        level: 32,
        puzzle: "GATCGGATTCCA",
        requiredMoves: 5,
      },
      {
        level: 33,
        puzzle: "GATCTTGCATAA",
        requiredMoves: 5,
      },
      {
        level: 34,
        puzzle: "CTATGGACAGCT",
        requiredMoves: 4,
        codon: "CGC",
        prompt:
          "Codon challenge!",
      },
      {
        level: 35,
        puzzle: "GTTCGAACCTAG",
        requiredMoves: 4,
        codon: "GAA",
        prompt:
          "Codon challenge!",
      },
    ]
  },
  {
    groupLabel: "Ultimate challenges",
    puzzles: [
      {
        level: 36,
        puzzle: "TTACAGGGATCC",
        requiredMoves: 5,
        allowRotate: true,
        codon: "CCG",
      },
      {
        level: 37,
        puzzle: "AGACTTCCTAGG",
        requiredMoves: 5,
        allowRotate: true,
        codon: "ATT",
      },
      {
        level: 38,
        puzzle: "TGAACTGGTACC",
        requiredMoves: 5,
        allowRotate: true,
        codon: "TAA",
      },
      {
        level: 39,
        puzzle: "CCGGTTGCCAGA",
        requiredMoves: 5,
        allowRotate: true,
        codon: "CAC",
      },
      {
        level: 40,
        puzzle: "CACTGCGTTAGA",
        requiredMoves: 5,
        allowRotate: true,
        codon: "CCC",
      },
    ]
  }
];

let maxLevel = 0;
let puzzles: Puzzle[] = [];
puzzleGroups.forEach((puzzleGroup) => {
  puzzleGroup.puzzles.forEach((puzzle) => {
    puzzles.push(puzzle);
    maxLevel = Math.max(maxLevel, puzzle.level);
  });
});

enum GameState {
  Menu = 1,
  Opening,
  Playing,
  Closing,
}

enum RightHalfView {
  None = 1,
  Game = 2,
  Accessibility = 3,
  Credits = 4,
}

function App() {
  const [loaded, setLoaded] = useState(false);
  const [unlocked, setUnlocked] = useState(true);
  const [level, setLevel] = useState(1);
  const [focusedLevel, setFocusedLevel] = useState(1);
  const [gameState, setGameState] = useState(GameState.Menu);
  const [rightHalfView, setRightHalfView] = useState(RightHalfView.None);
  const [idToFocus, setIdToFocus] = useState("");
  const [clientId, setClientId] = useState("");
  const noPuzzle: Puzzle = {
    level: 0,
    puzzle: "",
    requiredMoves: 0,
  };
  const [currentPuzzle, setCurrentPuzzle] = useState(noPuzzle);

  const tryMultipleTimes = (fn: () => boolean) => {
    let result = fn();
    if (!result) {
      setTimeout(() => {
        let result2 = fn();
        if (!result2) {
          setTimeout(() => {
            fn();
          }, 1000);
        }
      }, 1000);
    }
  };

  const setLevelTo = (level: number) => {
    setLevel(level);
    setFocusedLevel(level);
    localStorage.setItem("level", "" + level);
    tryMultipleTimes(() => {
      let levelButton = document.getElementById("level" + level);
      if (levelButton) {
        levelButton.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return true;
      } else {
        return false;
      }
    });
  };

  useEffect(() => {
    if (document.readyState === "complete") {
      setLoaded(true);
    } else {
      window.addEventListener("load", () => {
        setLoaded(true);
      });
    }

    let savedLevelString: string | null = localStorage.getItem("level");
    console.log("Saved level: " + savedLevelString);
    if (savedLevelString) {
      setLevelTo(parseInt(savedLevelString));
    }

    let savedClientId: string | null = localStorage.getItem("acgtClientId");
    if (savedClientId) {
      console.log("Reusing client id: " + savedClientId);
      setClientId(savedClientId);
      return;
    }

    fetch("/client_id/create", { method: "POST" })
      .then((response) => response.json())
      .then((data) => {
        let newClientId: string = data["client_id"];
        console.log("Got new client id: " + newClientId);
        localStorage.setItem("acgtClientId", newClientId);
        setClientId(newClientId);
      })
      .catch((error) => {
        console.error("Unable to create client id");
      });
  }, []);

  const playCurrentPuzzle = () => {
    setRightHalfView(RightHalfView.Game);
    setGameState(GameState.Opening);
    setTimeout(() => {
      setGameState(GameState.Playing);
    }, 2000);
  };

  const playPuzzle = (puzzle: Puzzle) => {
    setCurrentPuzzle(puzzle);
    playCurrentPuzzle();
  };

  const difficultyShortName = (difficulty: string) => {
    if (difficulty === "medium") {
      return "med";
    }
    return difficulty;
  };

  const playRandom = (difficulty: string) => {
    fetch("/puzzle/random/" + difficulty, { method: "POST" })
      .then((response) => response.json())
      .then((puzzleData) => {
        if (puzzleData.puzzle && puzzleData.swap_count) {
          setCurrentPuzzle({
            level: 0,
            levelName: difficultyShortName(difficulty),
            puzzle: puzzleData.puzzle,
            codon: puzzleData.codon,
            allowRotate: puzzleData.has_rotate,
            requiredMoves: puzzleData.swap_count,
          });
          playCurrentPuzzle();
        }
      })
      .catch((error) => {
        console.error("Unable to load puzzle");
      });
  };

  const goHome = () => {
    setGameState(GameState.Closing);
    setTimeout(() => {
      setGameState(GameState.Menu);
    }, 1000);
  };

  const onSolve = () => {
    setGameState(GameState.Closing);
    setTimeout(() => {
      if (currentPuzzle.level >= 1 && currentPuzzle.level === level) {
        setLevelTo(level + 1);
      }
      setRightHalfView(RightHalfView.None);
      setGameState(GameState.Menu);
    }, 2000);
  };

  const playing = () => {
    return gameState !== GameState.Menu;
  };

  const leftTabIndex = () => {
    if (gameState === GameState.Menu) {
      return 0;
    } else {
      return -1;
    }
  };

  const leftTabIndexForLevel = (level: number) => {
    if (leftTabIndex() == -1) {
      return -1;
    }

    return level == focusedLevel ? 0 : -1;
  };

  const leftDisabled = () => {
    if (gameState === GameState.Menu) {
      return false;
    } else {
      return true;
    }
  };

  const completedLevelsMessage = () => {
    if (level > maxLevel) {
      return (
        <div className="CompletedLevelsMessage">
          You completed all of the levels!
        </div>
      );
    } else {
      return <></>;
    }
  };

  const openRightHalfView = (v: RightHalfView) => {
    switch (v) {
      case RightHalfView.Accessibility:
        setIdToFocus("AccessibilityDialog");
        break;
      case RightHalfView.Credits:
        setIdToFocus("CreditsDialog");
        break;
      default:
        setIdToFocus("");
        break;
    }
    setRightHalfView(v);
    setGameState(GameState.Opening);
    setTimeout(() => {
      setGameState(GameState.Playing);
    }, 0);
  };

  useEffect(() => {
    if (idToFocus !== "" && document.getElementById(idToFocus) !== null) {
      setTimeout(() => {
        document.getElementById(idToFocus)?.focus();
      }, 0);
      setIdToFocus("");
    }
  });

  const onKeyDownForLevel = (e: React.KeyboardEvent<HTMLElement>) => {
    let newLevel: number | undefined = undefined;
    if (e.key === "ArrowDown" && focusedLevel < maxLevel) {
      newLevel = focusedLevel + 1;
    }
    if (e.key === "ArrowUp" && focusedLevel > 1) {
      newLevel = focusedLevel - 1;
    }
    if (newLevel === undefined) {
      return;
    }

    setFocusedLevel(newLevel);
    setTimeout(() => {
      let levelButton = document.getElementById("level" + newLevel);
      levelButton?.focus();
      levelButton?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 0);
    e.stopPropagation();
    e.preventDefault();
  };

  const leftHalf = () => {
    return (
      <>
        <img src={logo} alt="Spaghetti Codon"></img>
        <p className="Subheading">The DNA puzzle game</p>
        <h2 className="MainPageLabel">Levels</h2>
        <div className="ScrollingLevels">
          {puzzleGroups.map((puzzleGroup, index) => (
            <fieldset key={"group" + index}>
              <legend>{puzzleGroup.groupLabel}</legend>
              {puzzleGroup.puzzles.map((puzzle) => (
                <button
                  id={"level" + puzzle.level}
                  key={"level" + puzzle.level}
                  className={`Level ${
                    level > puzzle.level
                      ? "Solved"
                      : level === puzzle.level
                      ? "Next"
                      : ""
                  }`}
                  disabled={puzzle.level > level || leftDisabled()}
                  tabIndex={leftTabIndexForLevel(puzzle.level)}
                  onKeyDown={onKeyDownForLevel}
                  onClick={() => {
                    if (level >= puzzle.level) {
                      playPuzzle(puzzle);
                    }
                  }}
                >
                  Level {puzzle.level}
                  <div className="CheckWrapper">
                    <img
                      src={check_icon}
                      alt={level > puzzle.level ? "Solved" : ""}
                    ></img>
                  </div>
                </button>
              ))}
            </fieldset>
          ))}
          {completedLevelsMessage()}
        </div>
        <h2 className="MainPageLabel">Random</h2>
        <div className="PickerWrapper">
          <button
            tabIndex={leftTabIndex()}
            disabled={leftDisabled()}
            onClick={() => {
              playRandom("easy");
            }}
          >
            Easy
          </button>
          <button
            tabIndex={leftTabIndex()}
            disabled={leftDisabled()}
            onClick={() => {
              playRandom("medium");
            }}
          >
            Medium
          </button>
          <button
            tabIndex={leftTabIndex()}
            disabled={leftDisabled()}
            onClick={() => {
              playRandom("hard");
            }}
          >
            Hard
          </button>
        </div>
        <div className="LinkButton">
          <button
            tabIndex={leftTabIndex()}
            disabled={leftDisabled()}
            onClick={() => openRightHalfView(RightHalfView.Accessibility)}
          >
            Accessibility
          </button>
        </div>
        <div className="LinkButton">
          <button
            tabIndex={leftTabIndex()}
            disabled={leftDisabled()}
            onClick={() => openRightHalfView(RightHalfView.Credits)}
          >
            Credits
          </button>
        </div>
      </>
    );
  };

  const previewOnly = () => {
    return (
      <>
        <img src={logo} alt="Spaghetti Codon"></img>
        <p className="Subheading">The DNA puzzle game</p>
        <p className="Subheading">
          This game is in limited preview. Use your access code if you have one,
          otherwise check back later!
        </p>
      </>
    );
  };

  const scrollingTextViewGameState = () => {
    switch (gameState) {
      case GameState.Closing:
      case GameState.Menu:
      case GameState.Opening:
        return "ScrollingTextView";
      case GameState.Playing:
        return "ScrollingTextView Visible";
    }
  };

  const shouldHideDoubleHelix = () => {
    return !common.browserSupportsDoubleHelixAnimation();
  };

  const rightHalf = () => {
    switch (rightHalfView) {
      case RightHalfView.None:
        return <></>;
      case RightHalfView.Game:
        return (
          <>
            <div
              hidden={shouldHideDoubleHelix()}
              className="DoubleHelixWrapper"
            >
              <div className="DoubleHelixClip">
                <div className="DoubleHelix"></div>
              </div>
            </div>
            {playing() ? (
              <Game
                clientId={clientId}
                puzzle={currentPuzzle.puzzle}
                requiredMoves={currentPuzzle.requiredMoves || 0}
                codon={currentPuzzle.codon || ""}
                prompt={currentPuzzle.prompt || ""}
                bottomPrompt={currentPuzzle.bottomPrompt || ""}
                allowRotate={currentPuzzle.allowRotate || false}
                level={currentPuzzle.levelName || "" + currentPuzzle.level}
                closing={gameState === GameState.Closing}
                onHome={goHome}
                onSolve={onSolve}
              />
            ) : (
              <></>
            )}
          </>
        );
      case RightHalfView.Accessibility:
        return (
          <div
            id="AccessibilityDialog"
            tabIndex={0}
            className={scrollingTextViewGameState()}
            aria-label="Accessibility"
            role="dialog"
            aria-modal="true"
          >
            <h2>Accessibility</h2>
            <p>
              This game has been designed to be accessible to as many users as
              possible! See below for information on how to play with the mouse,
              keyboard, and screen reader.
            </p>
            <h3>Mouse</h3>
            <p>
              It's not necessary to be able to drag to play the game. To swap
              two pieces, click on one of them and you will see arrows appear.
              Click on the arrow corresponding to the direction you want the
              piece to swap.
            </p>
            <h3>Keyboard</h3>
            <p>
              Use Tab to move focus. You can also use arrow keys to navigate to
              a different piece directionally.
            </p>
            <p>
              Use Space to click and activate one piece, then press Tab to
              navigate to the contextual arrows that move that piece in a given
              direction.
            </p>
            <p>
              As a shortcut to swapping two pieces, hold down Shift and press
              the arrow key corresponding to the direction you want it to move,
              for example Shift+Down will swap the current focused piece with
              its neighbor immediately below it.
            </p>
            <h3>Screen Reader</h3>
            <p>
              Everything in the game is designed to be screen-reader-friendly.
            </p>
            <p>
              The game is played on a grid with 2 columns and some number of
              rows. In each cell in the grid is one DNA base: either A, C, G, or
              T. The spatial relationship between the cells is important because
              to solve each puzzle, every row must contain a DNA base pair,
              either A and T (in either order), or C and G (in either order).
            </p>
            <p>
              On desktop with a keyboard, press Tab until you're inside the
              grid, then use arrow keys to move within the grid. You can
              activate a cell by pressing Space, and then when you press Tab
              you'll find buttons to move that cell in various directions, which
              will cause it to swap with whatever is in that other adjacent
              cell.
            </p>
            <p>
              As a shortcut to move a cell you can hold down Shift and press an
              arrow key to swap a cell with its neighbor in that corresponding
              direction.
            </p>
            <p>
              On mobile, you can use a combination of forward and backwards
              navigation or touch exploration to navigate to cells in the grid.
              Double-tap to activate a cell and then navigate to the next
              elements on the page to find additional contextual buttons that
              move that cell in a specific direction.
            </p>
            <p>
              You can find status messages and other information about the
              current level above the game board, and buttons to go home, reset
              the board, or get help below the game board.
            </p>
            <p>
              When you correctly solve the puzzle, it will return you to the
              main menu. If you fail to solve the puzzle within the required
              number of moves, the game will announce Sorry, and the grid will
              reset so you can try again.
            </p>
            <div className="CloseWrapper">
              <button onClick={goHome}>Close</button>
            </div>
          </div>
        );
      case RightHalfView.Credits:
        return (
          <div
            id="CreditsDialog"
            tabIndex={0}
            className={scrollingTextViewGameState()}
            aria-label="Credits"
            role="dialog"
            aria-modal="true"
          >
            <h2>Credits</h2>
            <p>
              Game concept and programming by Dominic Mazzoni, Copyright 2022 -
              2023.
            </p>
            <p>
              Email:
              <code>dmazzoni AT gmail DOT com</code>
            </p>
            <p>Graphics and design by Draco Art Studio.</p>
            <div className="CloseWrapper">
              <button onClick={goHome}>Close</button>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="AppBackground">
      <div className="AppZoomer">
        <div
          className={`App ${loaded ? "Loaded" : "Loading"} ${
            gameState === GameState.Opening || gameState === GameState.Playing
              ? "Right"
              : "Left"
          }`}
        >
          <div className="LeftHalf">
            {unlocked ? leftHalf() : previewOnly()}
          </div>
          <div className="RightHalf">{rightHalf()}</div>
        </div>
        <div className="Preloader"></div>
        <svg
          aria-hidden="true"
          viewBox="0 0 0 0"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter id="ShapeFilter" height="220%">
              <feFlood floodColor="#000000" />
              <feComposite operator="out" in2="SourceGraphic" />
              <feGaussianBlur stdDeviation="10" />
              <feComposite operator="atop" in2="SourceGraphic" />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  );
}

export default App;
